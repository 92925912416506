import { urlVersionPrefix, urlVersionPrefixV2 } from '@constants/api/api'
import type { ConfigurationFrontendTypes } from '@constants/configuration/configuration'

export default {
  // /api/v1/frontend-ui/{type}
  getConfiguration: (type: ConfigurationFrontendTypes) => `${urlVersionPrefix}frontend-ui/${type}`,

  // /api/v2/currencies
  getCurrencies: `${urlVersionPrefixV2}currencies`,

  // api/v1/email/misprint
  getEmailMisprints: `${urlVersionPrefix}email/misprint`,

  // /api/v2/entity/file/${fileId}
  getFile: (fileId: number) => `${urlVersionPrefixV2}entity/file/${fileId}`,

  // /api/v1/languages
  getLanguages: `${urlVersionPrefix}languages`,

  // /api/v1/site/info?${document.location.search}
  getSiteInfo: `${urlVersionPrefix}site/info${typeof window !== 'undefined' ? document.location.search : ''}`,

  // /currency/switch/${currency}
  switchCurrency: (currency: string) => `/currency/switch/${currency}`,
}
